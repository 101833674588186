import "./libs.bundle.js";
import "./jquery-bootstrap";
import "./xhiveframework/class.js";
import "./xhiveframework/polyfill.js";
import "./lib/moment.js";
import "./xhiveframework/provide.js";
import "./xhiveframework/translate.js";
import "./xhiveframework/form/formatters.js";
import "./xhiveframework/format.js";
import "./xhiveframework/utils/number_format.js";
import "./xhiveframework/utils/utils.js";
import "./xhiveframework/utils/common.js";
import "./xhiveframework/ui/messages.js";
import "./xhiveframework/utils/pretty_date.js";
import "./xhiveframework/utils/datetime.js";
import "./xhiveframework/microtemplate.js";
import "./xhiveframework/query_string.js";

import "./xhiveframework/upload.js";

import "./xhiveframework/model/meta.js";
import "./xhiveframework/model/model.js";
import "./xhiveframework/model/perm.js";

import "./bootstrap-4-web.bundle";

import "../../website/js/website.js";
import "./xhiveframework/socketio_client.js";
